import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '/Users/manikantayedlapally/abcd/src/cowhite.png';

function LoginPage({ setIsLoggedIn }) {
  const [fullName, setFullName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [mailId, setMailId] = useState('');
  const [otpRequested, setOtpRequested] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [loadingVisible, setLoadingVisible] = useState(false);
  const navigate = useNavigate();
  
  const otpRefs = useRef([]);

  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';

    // Clean up by enabling scrolling on unmount
    return () => {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    };
  }, []);

  const handleRequestOTP = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!fullName || mobileNumber.length !== 10 || !emailRegex.test(mailId)) {
      alert('Please enter a valid name, 10-digit mobile number, and email address.');
      return;
    }
  
    try {
      setLoadingVisible(true);
      // const response = await axios.post('http://192.168.29.80:5001/send-otp', 
      const response = await axios.post('https://cosmyk.uc.r.appspot.com/send-otp',
         {
        name: fullName,
        mobile: mobileNumber,
        email: mailId,
      });
      setOtpRequested(true);
    } catch (error) {
      alert(error.response?.data?.error || 'Failed to request OTP');
    } finally {
      setLoadingVisible(false);
    }
  };

  
  const handleOtpChange = (text, index) => {
    if (text.match(/[0-9]/) || text === '') {
      let newOtp = [...otp];
      newOtp[index] = text;
      setOtp(newOtp);

      if (text && index < otp.length - 1) {
        otpRefs.current[index + 1].focus();
      } else if (text === '' && index > 0) {
        otpRefs.current[index - 1].focus();
      }
    }
  };

  const handleVerifyOtp = async () => {
    if (otp.join('').length === 6) {
      try {
        setLoadingVisible(true); // Show loading popup
        const otpValue = otp.join('');
         // const response = await axios.post('http://192.168.29.80:5001/verify-otp', 
        const response = await axios.post('https://cosmyk.uc.r.appspot.com/verify-otp', 
          {
          email: mailId,
          otp: otpValue,
        });
  
        // alert(response.data.message);
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('userEmail', mailId);
        navigate('/', { replace: true });
      } catch (error) {
        alert(error.response?.data?.error || 'Failed to verify OTP');
      } finally {
        setLoadingVisible(false); // Hide loading popup
      }
    } else {
      alert('Please enter a valid 6-digit OTP');
    }
  };
  

  return (
    <div style={styles.app}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <h1 style={styles.title}>cosmyk.in</h1>

      {loadingVisible && (
  <div style={styles.loadingPopup}>
    {otpRequested ? (
      <>
        Verifying OTP...
      </>
    ) : (
      <>
        Requesting OTP... <br />
        Please check your email for the OTP.
      </>
    )}
  </div>
)}

      {!otpRequested ? (
        <>
          <input
            type="text"
            placeholder="Enter username / store name"
            style={styles.input}
            value={fullName}
            onChange={(e) => setFullName(e.target.value.toLowerCase())} // Convert to lowercase
          />

          <input
            type="text"
            placeholder="Enter mobile number"
            style={styles.input}
            maxLength={10}
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value.replace(/[^0-9]/g, ''))}
          />

          <input
            type="email"
            placeholder="Enter email to request otp"
            style={styles.input}
            value={mailId}
            onChange={(e) => setMailId(e.target.value)}
          />

          <button style={styles.button} onClick={handleRequestOTP}>
            Request Email OTP
          </button>
        </>
        
      ) : (
        <>
          <h2 style={styles.otpTitle}>Enter OTP</h2>
          <div style={styles.otpContainer}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                style={styles.otpInput}
                maxLength={1}
                value={digit}
                onChange={(e) => handleOtpChange(e.target.value, index)}
                ref={(ref) => (otpRefs.current[index] = ref)}
              />
            ))}
          </div>
          <button style={styles.button} onClick={handleVerifyOtp}>
            Verify OTP
          </button>
        </>
      )}

      {/* {loadingVisible && (
        <div style={styles.loadingMessage}>Please check your email for the OTP.</div>
      )} */}
    </div>
  );
}

const styles = {
  app: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: 'white',
    padding: '0 10px',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  logo: {
    width: '200px',
    height: 'auto',
    marginTop: '-40px',
    marginBottom: '-20px',
  },
  title: {
    fontSize: '2rem',
    marginBottom: '20px',
    color: '#151515',
  },
  input: {
    width: '100%',
    maxWidth: '350px',
    padding: '10px',
    margin: '10px 0',
    border: '1px solid #151515',
    borderRadius: '5px',
    color: '#151515',
    textAlign: 'left',
  },
  button: {
    backgroundColor: 'black',
    border: '2px solid white', // Add a border color
    color: 'white',
    padding: '10px 30px', // Adjust padding for a more oval shape
    fontSize: '1rem',
    cursor: 'pointer',
    borderRadius: '100px', // Increase border radius for a more oval shape
    transition: 'background-color 0.3s',
    boxSizing: 'border-box',
  },
  otpTitle: {
    fontSize: '1.5rem',
    marginBottom: '20px',
    color: '#151515',
  },
  otpContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '350px',
  },
  otpInput: {
    height: '50px',
    width: '40px',
    backgroundColor: '#EEEEEE',
    margin: '10px 5px',
    border: '1px solid #151515',
    borderRadius: '5px',
    textAlign: 'center',
    fontSize: '18px',
  },
  loadingMessage: {
    marginTop: '10px',
    color: '#151515',
    textAlign: 'center',
    fontSize: '16px',
  },
  loadingPopup: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '20px',
    zIndex: 9999,
  },
  
};

export default LoginPage;
