import React, { useState, useEffect } from 'react';
import logo from '../cowhite.png'; // Adjust the path as necessary
import axios from 'axios'; // Ensure axios is installed

const ApproveStoreDashboard = () => {
  const [selectedTab, setSelectedTab] = useState('Requested Stores'); // Default tab
  const [requestedStores, setRequestedStores] = useState([]); // Stores data for "Requested Stores"
  const [approvedStores, setApprovedStores] = useState([]); // Stores data for "Approved Stores"

  // Fetch data for the selected tab
  const fetchData = async () => {
    try {
      // const requestedStoresResponse = await axios.get('http://192.168.29.80:5001/get-requested-stores');
      // const approvedStoresResponse = await axios.get('http://192.168.29.80:5001/get-approved-stores');

      const requestedStoresResponse = await axios.get('https://cosmyk.uc.r.appspot.com/get-requested-stores');
      const approvedStoresResponse = await axios.get('https://cosmyk.uc.r.appspot.com/get-approved-stores');
      
      setRequestedStores(requestedStoresResponse.data.requested_stores);
      setApprovedStores(approvedStoresResponse.data.approved_stores);
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on initial load
  }, []); // Empty dependency array ensures it's fetched only once when the component mounts

  const handleApprove = async (email) => {
    try {
      // Send a POST request to approve the store
      // const response = await axios.post('http://192.168.29.80:5001/approve-store', { email });
      const response = await axios.post('https://cosmyk.uc.r.appspot.com/approve-store', { email });


      if (response.status === 200) {
        alert(response.data.message); // Show success message
        // After approval, refetch data to update both tabs
        fetchData();
      } else {
        alert(response.data.message); // Show error or info message
      }
    } catch (error) {
      console.error('Error approving store:', error);
      alert('Failed to approve the store. Please try again.');
    }
  };

  const handleRemoveApproval = async (email) => {
    try {
      // const response = await axios.post('http://192.168.29.80:5001/remove-approval', { email });

      const response = await axios.post('https://cosmyk.uc.r.appspot.com/remove-approval', { email });
  
      if (response.status === 200) {
        alert(response.data.message); // Show success message
        fetchData(); // Refresh data to update the Approved Stores list
      } else {
        alert(response.data.message); // Show error message
      }
    } catch (error) {
      console.error('Error removing approval:', error);
      alert('Failed to remove approval. Please try again.');
    }
  };

  // Helper function to calculate days left to the next 30 days from created date
const calculateDaysTo30DaysFromCreatedDate = (createdDate) => {
    const currentDate = new Date();
    const createdDateObj = new Date(createdDate);
  
    // Add 30 days to the created date
    const targetDate = new Date(createdDateObj);
    targetDate.setDate(createdDateObj.getDate() + 30); // Add 30 days
  
    // Calculate the difference in time
    const timeDifference = targetDate - currentDate;
    const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert milliseconds to days
  
    return daysLeft;
  };
  

  const styles = {
    dashboardWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '-20px',
      minHeight: '100vh',
      overflowY: 'auto',
      padding: '0 10px', // Add some padding on small screens
    },
    container: {
      padding: '10px 20px',
      fontFamily: 'Arial, sans-serif',
      color: '#333',
      maxWidth: '1000px',
      width: '100%',
    },
    heading: {
      margin: '5px 0',
      textAlign: 'center',
    },
    logo: {
      width: '100px',
      height: '100px',
      marginBottom: '5px',
    },
    tabsContainer: {
      display: 'flex',
      flexWrap: 'wrap', // Allow tabs to wrap to the next line on smaller screens
      justifyContent: 'space-between',
      marginBottom: '15px',
    },
    tab: (isSelected) => ({
      backgroundColor: isSelected ? '#C5CAE9' : '#E8EAF6',
      padding: '8px 15px',
      borderRadius: '5px',
      cursor: 'pointer',
      border: isSelected ? '2px solid #3f51b5' : 'none',
      textAlign: 'center',
      fontSize: '14px',
      transition: 'background-color 0.3s',
      whiteSpace: 'nowrap',
      margin: '5px', // Reduced margin to fit better on smaller screens
    }),
    tabContent: {
      marginTop: '20px',
      fontSize: '16px',
      textAlign: 'center',
    },
    cardContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      gap: '20px',
      marginTop: '20px',
      // Ensure grid adapts based on screen size
      '@media (max-width: 600px)': {
        gridTemplateColumns: '1fr', // Stack the cards on small screens
      },
    },
    card: {
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '15px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      backgroundColor: '#fff',
    },
    cardImage: {
      width: '100%',
      height: '200px',
      objectFit: 'cover',
      borderRadius: '8px',
    },
    cardInfo: {
      marginTop: '10px',
    },
    cardTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#3f51b5',
    },
    cardText: {
      fontSize: '14px',
      color: '#555',
      margin: '5px 0',
    },
    button: {
      backgroundColor: 'black',
      border: '2px solid white',
      color: 'white',
      padding: '10px 30px',
      fontSize: '1rem',
      cursor: 'pointer',
      borderRadius: '100px',
      transition: 'background-color 0.3s',
      boxSizing: 'border-box',
      marginTop: '15px',
      '@media (max-width: 600px)': {
        padding: '8px 20px', // Adjust button size on mobile
        fontSize: '0.9rem', // Reduce font size on mobile
      },
    },
  };
  

  return (
    <div style={styles.dashboardWrapper}>
      <div style={styles.container}>
        <img src={logo} alt="Cosmyk Logo" style={styles.logo} />
        <h6 style={styles.heading}>Cosmyk Store Approval Dashboard</h6>
        
        <div style={styles.tabsContainer}>
          <div
            style={styles.tab(selectedTab === 'Requested Stores')}
            onClick={() => setSelectedTab('Requested Stores')}
          >
            Requested Stores
          </div>
          <div
            style={styles.tab(selectedTab === 'Approved Stores')}
            onClick={() => setSelectedTab('Approved Stores')}
          >
            Approved Stores
          </div>
          <div
            style={styles.tab(selectedTab === 'Number of Stores')}
            onClick={() => setSelectedTab('Number of Stores')}
          >
            Number of Stores
          </div>
        </div>

        <div style={styles.tabContent}>
          <h4>{selectedTab}</h4>
          <div>
            {selectedTab === 'Requested Stores' ? (
              // Requested Stores Content
              <>
                {requestedStores.length > 0 ? (
                  <div style={styles.cardContainer}>
                    {requestedStores.map((store, index) => (
                      <div key={index} style={styles.card}>
                        <div style={styles.cardInfo}>
                          <div style={styles.cardTitle}>Store Name: {store.store_name}</div>
                          <div style={styles.cardText}><strong>Email:</strong> {store.mail}</div>
                          <div style={styles.cardText}><strong>Mobile:</strong> {store.mobile}</div>
                          <div style={styles.cardText}><strong>Status:</strong> {store.status}</div>
                          <button 
                            style={styles.button}
                            onClick={() => handleApprove(store.mail)} // Pass store email to the approve function
                          >
                            Approve Store
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No data available for Requested Stores.</p>
                )}
              </>
            ) : selectedTab === 'Approved Stores' ? (
              // Approved Stores Content
              <>
                {approvedStores.length > 0 ? (
                  <div style={styles.cardContainer}>
                    {approvedStores.map((store, index) => (
                      <div key={index} style={styles.card}>
                        <div style={styles.cardInfo}>
                          <div style={styles.cardTitle}>Store Name: {store.store_name}</div>
                          <div style={styles.cardText}><strong>Email:</strong> {store.mail}</div>
                          <div style={styles.cardText}><strong>Mobile:</strong> {store.mobile}</div>
                          <div style={styles.cardText}><strong>Status:</strong> {store.status}</div>
                          <button 
                            style={styles.button} 
                            onClick={() => handleRemoveApproval(store.mail)}
                          >
                            Remove Approval
                          </button>
                          <div style={styles.cardText}>
                            <strong>Days left to Next Renewal:</strong> {calculateDaysTo30DaysFromCreatedDate(store.approved_date)} days
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No data available for Approved Stores.</p>
                )}
              </>
            ) : selectedTab === 'Number of Stores' ? (
              // Number of Stores Content
              <div>
                {approvedStores.length > 0 ? (
                  <p>Total Number of Approved Stores: {approvedStores.length}</p>
                ) : (
                  <p>No approved stores available.</p>
                )}
              </div>
            ) : (
              <p>No data available for this tab.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveStoreDashboard;
