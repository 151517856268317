import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const FetchPosts = ({ email }) => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxMedia, setLightboxMedia] = useState([]);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const navigate = useNavigate();
  const videoRefs = useRef([]);

  // Fetch posts data
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // const response = await fetch(`http://192.168.29.80:5001/get-posts?email=${email}`);
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-posts?email=${email}`);
        if (response.ok) {
          const data = await response.json();
          setPosts(data);
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch posts');
        }
      } catch (error) {
        setError('Something went wrong. Please try again later.');
      }
    };

    if (email) fetchPosts();
  }, [email]);

  // Function to render dots under images
  const renderDots = (mediaCount, activeIndex) => (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3px' }}>
      {Array.from({ length: mediaCount }, (_, index) => (
        <div
          key={index}
          style={{
            height: '3px',
            width: '3px',
            borderRadius: '50%',
            backgroundColor: activeIndex === index ? 'black' : '#ccc',
            margin: '0 3px',
          }}
        />
      ))}
    </div>
  );

  const handleMediaClick = (index, media) => {
    setLightboxMedia(media);
    setLightboxIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => setIsLightboxOpen(false);
  const nextMedia = () => setLightboxIndex((prevIndex) => (prevIndex + 1) % lightboxMedia.length);
  const prevMedia = () => setLightboxIndex((prevIndex) => (prevIndex - 1 + lightboxMedia.length) % lightboxMedia.length);

  const handlePostClick = (post) => {
    navigate('/post-details', { state: { post, email } });
  };

 // Function to render media (image or video)
const renderMedia = (mediaUrl, index) => {
  const extension = mediaUrl.split('.').pop().toLowerCase();
  if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 'auto',
          background: `url(${mediaUrl}) center center no-repeat`,
          backgroundSize: 'contain',
          borderRadius: '6px',
          marginBottom: '5px',
          overflow: 'hidden',
          aspectRatio: '9/16', // Maintain 9:16 aspect ratio
        }}
      />
    );
  } else if (['mp4', 'webm', 'avi'].includes(extension)) {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 'auto',
          backgroundColor: 'black',
          borderRadius: '6px',
          overflow: 'hidden',
          marginBottom: '5px',
          aspectRatio: '9/16', // Maintain 9:16 aspect ratio
        }}
      >
        <video
          ref={(el) => (videoRefs.current[index] = el)}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Ensure video fills the container while maintaining 9:16 ratio
            borderRadius: '6px',
          }}
          controls
          controlsList="nodownload nofullscreen noplaybackrate"
        >
          <source src={mediaUrl} type={`video/${extension}`} />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }
  return null;
};


  // Lightbox and media styles
  const postLightboxStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30vw', // Ensures it takes up 80% of the viewport width
    maxWidth: '90%',
    height: 'auto',
    maxHeight: '90vh',
    backgroundColor: 'black',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    overflow: 'hidden',
    aspectRatio: '9 / 16', // Ensure aspect ratio is maintained on mobile too
    '@media (max-width: 768px)': {
      width: '90vw', // Take up more space on mobile
      height: 'auto',
      maxHeight: '80vh', // Limit the height to prevent overflow
    }
  };

  const overlayStyleForPost = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 999,
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: 'white',
    fontSize: '1.5rem',
    cursor: 'pointer',
    zIndex: 1001,
    '@media (max-width: 768px)': {
      top: '5px',
      right: '5px',
      fontSize: '1.2rem', // Make the close button smaller on mobile
    },
  };

  const reelContainerStyle = {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    padding: '0 10px',
    gap: '10px', // Add gap between items for better alignment
  };

  const reelStyle = {
    width: 'auto',
    aspectRatio: '9 / 16', // Maintain the 9:16 aspect ratio
    objectFit: 'cover',
    borderRadius: '8px',
    border: '2px solid #ddd',
    margin: '10px',
    maxWidth: '90vw', // Take up the full width of the viewport
    cursor: 'pointer',
    '@media (min-width: 768px)': {
      height: 'calc((100vw - 120px) / 5 * 16 / 9)',
      maxWidth: 'calc((100vw - 120px) / 5)',
    },
    '@media (min-width: 1024px)': {
      height: 'calc((100vw - 200px) / 6 * 16 / 9)',
      maxWidth: '200px',
      maxHeight: '356px',
    },
  };

  const getReelStyle = () => {
    const width = window.innerWidth;

    if (width >= 1024) { // Desktop
      return {
        ...reelStyle,
        height: '356px', // Fixed height for desktop
        maxWidth: '200px', // Fixed width for desktop
      };
    } else if (width >= 768) { // Tablet
      return {
        ...reelStyle,
        height: 'calc((100vw - 120px) / 5 * 16 / 9)',
        maxWidth: 'calc((100vw - 120px) / 5)',
      };
    }
    return reelStyle; // Mobile
  };

  return (
    <div
      style={{
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {error ? (
        <p style={{ color: 'red', fontSize: '0.8rem' }}>{error}</p>
      ) : posts.length === 0 ? (
        <p style={{ marginTop: '15px', fontSize: '0.8rem', color: '#333' }}>No Posts available</p>
      ) : (
        <div style={reelContainerStyle}>
          {posts.map((post, index) => (
            <div key={index} style={{ ...getReelStyle(), marginBottom: '10px' }}>
              <div onClick={() => handleMediaClick(0, post.media_urls)} style={{ cursor: 'pointer' }}>
                {renderMedia(post.media_urls[0], index)}
              </div>

              {/* Title and button */}
              <div style={{ padding: '6px', textAlign: 'center' }}>
                <p
                  style={{
                    fontSize: '0.75rem',
                    color: 'black',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginBottom: '5px',
                  }}
                >
                  {post.title.length > 18 ? `${post.title.slice(0, 18)}...` : post.title}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Lightbox */}
      {/* {isLightboxOpen && lightboxMedia.length > 0 && (
        <div style={overlayStyleForPost} onClick={closeLightbox}>
          <div style={postLightboxStyle} onClick={(e) => e.stopPropagation()}>
            {renderMedia(lightboxMedia[lightboxIndex], lightboxIndex)}
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: 'white',
              }}
              onClick={prevMedia}
            >
              &#10094;
            </div>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: 'white',
              }}
              onClick={nextMedia}
            >
              &#10095;
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default FetchPosts;
