import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BottomNavbar from './BottomNavbar';
import defaultProfilePic from '../coblack.png';
import { useNavigate } from 'react-router-dom';
import FetchAppointments from './FetchAppointments';
import FetchPosts from './FetchPost';

const StorePage = () => {
  const navigate = useNavigate();
  const { storeName } = useParams();
  const { storeEmail } = useParams();
  const {storeMobile} = useParams();
  const [storeDetails, setStoreDetails] = useState({ imageUrl: defaultProfilePic, name: storeName, email: storeEmail, mobile: storeMobile});
  const [products, setProducts] = useState([]);
  const [selectedTab, setSelectedTab] = useState('Customize Store');
  const [message, setMessage] = useState('explore store products here and order now');
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [quantities, setQuantities] = useState({});
  const totalSelectedProducts = Object.values(quantities).reduce((sum, quantity) => sum + quantity, 0);
  const [loadingVisible, setLoadingVisible] = useState(false);


  const handleQuantityChange = (productName, change) => {
    setQuantities((prev) => {
      const currentQuantity = prev[productName] || 0;
      const product = products.find((p) => p.name === productName);
      const maxQuantity = product ? product.stock : 0;
  
      // Ensure quantity doesn't exceed stock and doesn't go below 0
      const newQuantity = Math.max(0, Math.min(currentQuantity + change, maxQuantity));
      const updatedQuantities = { ...prev, [productName]: newQuantity };
  
      sessionStorage.setItem(`selectedProducts_${storeName}`, JSON.stringify(updatedQuantities));
      return updatedQuantities;
    });
  };
  
  

  useEffect(() => {
    const savedQuantities = JSON.parse(sessionStorage.getItem(`selectedProducts_${storeName}`));
    if (savedQuantities) {
        setQuantities(savedQuantities);
    } else {
        setQuantities({}); // Reset quantities if no saved data
    }
}, [storeName]);

  
  

  const handleImageClick = (index, productIndex) => {
    setLightboxImages(products[productIndex].images);
    setLightboxIndex(index);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const nextImage = () => {
    setLightboxIndex((prevIndex) => (prevIndex + 1) % lightboxImages.length);
  };

  const prevImage = () => {
    setLightboxIndex((prevIndex) => (prevIndex - 1 + lightboxImages.length) % lightboxImages.length);
  };

  const getSelectedProducts = () => {
    return products
      .filter(product => quantities[product.name] > 0) // Get products with selected quantities
      .map(product => ({
        name: product.name,
        quantity: quantities[product.name],
        price: product.price,
        total: product.price * quantities[product.name],
        imageUrl: product.images[0], // Include the product image URL
        storeName: storeDetails.name,
        storeEmail: storeDetails.email,
        storeMobile: storeDetails.mobile

      }));
  };
  

  const renderDots = (imageCount, activeIndex) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
        {Array.from({ length: imageCount }, (_, index) => (
          <div key={index} style={{
            height: '5px',
            width: '5px',
            borderRadius: '50%',
            backgroundColor: activeIndex === index ? 'black' : '#ccc',
            margin: '0 5px',
          }} />
        ))}
      </div>
    );
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: '100vh',
    backgroundColor: 'white',
    padding: '20px',
    overflowY: 'auto',
  };

  const profileContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '150px',
    justifyContent: 'center',
  };

  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    marginBottom: '10px',
  };

  const profilePicStyle = {
    width: '80px',
    height: '80px',
    marginRight: '20px',
    border: '2px solid #ddd',
    borderRadius: '50%', // Makes the image circular
  };

  const nameStyle = {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#333',
  };

  const subtitleStyle = {
    fontSize: '1rem',
    color: '#666',
  };

  const namesContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    width: '100%',
    maxWidth: '300px',
  };

  const nameStyleInRow = (name) => ({
    fontSize: '1rem',
    color: 'black',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: selectedTab === name ? 'underline' : 'none',
    margin: '0 10px',
  });

  const messageContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    width: '100%',
  };

  const imageContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    padding: '10px',
    gap: '20px', // Spacing between images
  };
  
  const imageStyle = {
    width: '200px', // Fixed width for a square
    height: '200px', // Matches width for square shape
    objectFit: 'cover', // Ensures image fills the square proportionally
    borderRadius: '8px',
    border: '2px solid #ddd',
    margin: '10px',
    cursor: 'pointer',
  };

  const productTextStyle = {
    fontSize: '0.9rem',
    color: '#333',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center'
  };

  const orderButtonStyle = {
    padding: '5px 10px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '100px',
    cursor: 'pointer',
    margin: '0 5px',
    textAlign: 'center',
    fontSize: '0.8rem',
    transition: 'background-color 0.2s ease',
    border: '2px solid white', 
    boxSizing: 'border-box',
  };

  
  
  const orderButtonDisabledStyle = {
    ...orderButtonStyle,
    backgroundColor: '#ccc', // Gray color for disabled button
    cursor: 'not-allowed',
  };

 // Update floatingOrderButtonStyle for better visibility
const floatingOrderButtonStyle = {
  position: 'fixed',
  bottom: '100px',              // Adjusted position for visibility
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '15px 30px',        // Larger padding for prominence
  backgroundColor: 'black',  // Swiggy-style green color
  color: 'white',
  borderRadius: '25px',        // More oval shape
  fontSize: '1.1rem',          // Slightly larger font size
  cursor: 'pointer',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
  zIndex: 1001,                // Ensure it’s above all other elements
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
  
  useEffect(() => {
    const fetchStoreDetails = async () => {
      try {
        // const response = await fetch(`http://192.168.29.80:5001/get-store-details?name=${storeName}`);
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-store-details?name=${storeName}`);
        if (response.ok) {
          const data = await response.json();
          setStoreDetails({ 
            imageUrl: data.image_url || defaultProfilePic, 
            name: data.store_name,
            email: data.mail,
            mobile: data.mobile 
          });
          if (data.mail) {
            fetchProducts(data.mail);
          }
        } else {
          console.error('Error fetching store details:', response.status);
        }
      } catch (error) {
        console.error("Error fetching store details:", error);
      }
    };

    const fetchProducts = async (email) => {
      try {
        setLoadingVisible(true); // Show loading message
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-products?email=${email}`);
        if (response.ok) {
          const productData = await response.json();
          console.log(productData); // Check if this logs product data correctly
          const images = productData.map(product => ({
            name: product.name,
            stock: product.stock_quantity,
            images: product.image_urls.length > 0 ? product.image_urls : [defaultProfilePic], // Fallback to a default image if none provided
            price: product.price,
            description: product.description
          }));
          setProducts(images); // Set products state
        } else {
          console.error('Error fetching products:', response.status);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoadingVisible(false); // Hide loading message after the fetch completes
      }
    };
    
    
    
    fetchStoreDetails();
    console.log(products); // Check if products are set correctly
  }, [storeName]);

  
  

  const handleTabClick = (name) => {
    setSelectedTab(name);
    setMessage(name === 'Customize Store' ? 'explore store products here and order now' : name === 'Set Appointment' ? 'book appointments here' : 'explore store posts and updates here');
  };
  

  return (
    <div style={containerStyle}>
      {/* Store Section */}
      <div style={profileContainerStyle}>
        <header style={headerStyle}>
          <img src={storeDetails.imageUrl} alt="Store" style={profilePicStyle} />
          <div style={{ textAlign: 'center' }}>
            <h2 style={nameStyle}>{storeDetails.name}</h2>
            <p style={subtitleStyle}>powered by cosmyk.in</p>
          </div>
        </header>
      </div>
      
     
      {loadingVisible && (
  <div style={{
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '24px',
    zIndex: 9999,
  }}>
    Explore Store
  </div>
)}


      {/* Tab Names for Selecting */}
      <div style={namesContainerStyle}>
        
     


        <div 
          style={nameStyleInRow('Customize Store')} 
          onClick={() => handleTabClick('Customize Store')}
        >
         Explore Products
        </div>
        <div 
  style={nameStyleInRow('Posts')} 
  onClick={() => handleTabClick('Posts')}
>
  Product Videos
</div>
        {/* <div 
          style={nameStyleInRow('Set Appointment')} 
          onClick={() => handleTabClick('Set Appointment')}
        >
          Book Slots
        </div> */}
      </div>
      
      {/* Message and Product Display Section */}
      <div style={messageContainerStyle}>
        {/* <p style={{ marginTop: '20px', fontSize: '1rem', color: '#333' }}>{message}</p> */}

        {/* Images for Customize Store */}
        {selectedTab === 'Customize Store' && (
          <div style={imageContainerStyle}>
            {products.length > 0 ? (
  products.map((product, productIndex) => (
    <div key={productIndex} style={{ textAlign: 'center', margin: '10px' }}>
      <img 
        src={product.images[0]} // Display the first image
        alt={`Product ${productIndex + 1}`} 
        style={imageStyle} 
        onClick={() => handleImageClick(0, productIndex)} 
      />
      {renderDots(product.images.length, 0)} 
      <p style={productTextStyle}>{product.name.length > 20 ? `${product.name.slice(0, 20)}...` : product.name}</p>
      <p style={productTextStyle}>₹ {product.price}</p>
      <p style={productTextStyle}>Available Stock: {product.stock > 0 ? product.stock : 'Out of Stock'}</p>
      <p style={productTextStyle}>Description: {product.description.length > 22 ? `${product.description.slice(0, 22)}...` :product.description}</p>
      {/* Order Button and Quantity Control */}
      {product.stock > 0 ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {quantities[product.name] > 0 ? (
            <>
              <button 
                onClick={() => handleQuantityChange(product.name, -1)} 
                style={orderButtonStyle}
                disabled={(quantities[product.name] || 0) === 0}
              >
                -
              </button>
              <span style={{ margin: '0 10px' }}>{quantities[product.name]}</span>
              <button 
                onClick={() => handleQuantityChange(product.name, 1)} 
                style={orderButtonStyle}
              >
                +
              </button>
            </>
          ) : (
            <button 
              onClick={() => handleQuantityChange(product.name, 1)} 
              style={orderButtonStyle}
            >
              Order Now
            </button>
          )}
        </div>
      ) : (
        <div style={orderButtonDisabledStyle}>Out of Stock</div>
      )}
    </div>
  ))
) : (
  <p style={{ marginTop: '20px', fontSize: '1rem', color: '#333' }}>No Products Available</p>
)}

          </div>
        )}

        {selectedTab === 'Set Appointment' && (
  <FetchAppointments email={storeDetails.email} />
)}

{selectedTab === 'Posts' && (
  <div style={{ marginTop: '20px', textAlign: 'center', width: '100%' }}>
    {/* <p style={{ marginTop: '20px', fontSize: '1rem', color: '#333' }}>No Posts Available</p> */}
    <FetchPosts email={storeDetails.email} />
    {/* <p style={{ color: '#666', fontSize: '1rem' }}>
      Check out the latest updates, announcements, and posts from the store.
    </p> */}
    {/* Example Content */}
    {/* <ul style={{ listStyleType: 'none', padding: 0, margin: '20px 0' }}>
      <li style={{ margin: '10px 0', padding: '10px', border: '1px solid #ddd', borderRadius: '8px' }}>
        <h4 style={{ margin: '0 0 5px' }}>Grand Opening Sale!</h4>
        <p style={{ margin: 0 }}>Join us for our grand opening sale! Huge discounts on all products.</p>
      </li>
      <li style={{ margin: '10px 0', padding: '10px', border: '1px solid #ddd', borderRadius: '8px' }}>
        <h4 style={{ margin: '0 0 5px' }}>New Arrivals</h4>
        <p style={{ margin: 0 }}>Check out the latest additions to our product lineup!</p>
      </li>
    </ul> */}
  </div>
)}


        {/* Lightbox for full-screen view */}
        {isLightboxOpen && lightboxImages.length > 0 && (
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.8)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} onClick={closeLightbox}>
            <img src={lightboxImages[lightboxIndex]} alt="Selected Product" style={{ maxWidth: '90%', maxHeight: '80%', borderRadius: '8px' }} />
            {lightboxImages.length > 1 && (
              <>
                <div style={{ position: 'absolute', left: '5%', color: 'white', fontSize: '2rem', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); prevImage(); }}>&lt;</div>
                <div style={{ position: 'absolute', right: '5%', color: 'white', fontSize: '2rem', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); nextImage(); }}>&gt;</div>
              </>
            )}
          </div>
        )}
      </div>

      

      {/* Floating Continue to Place Order Button */}
      {totalSelectedProducts > 0 && (
// Update the onClick handler in the floating order button
<button
  style={floatingOrderButtonStyle}
  onClick={() => navigate('/ordersummary', { state: { selectedProducts: getSelectedProducts(), storeName: storeDetails.name, storeImage: storeDetails.imageUrl, storeEmail: storeDetails.email } })}
>
  Continue to Place Order ({totalSelectedProducts})
</button>
    )}
<p></p>
<p></p>
      {/* Bottom Navigation Bar */}
      <BottomNavbar />
      
    </div>
  );
};

export default StorePage;