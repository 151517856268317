import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../cowhite.png'; // Adjust the path as necessary

const DeliveryBoyDashboard = () => {
  const [selectedTab, setSelectedTab] = useState('Pending Orders');
  const [pendingOrders, setPendingOrders] = useState([]);
  const [dispatchedOrders, setDispatchedOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [earnings, setEarnings] = useState(0);
  const [todayCompletedOrders, setTodayCompletedOrders] = useState(0);
const [totalCompletedOrders, setTotalCompletedOrders] = useState(0);
const [todayEarnings, setTodayEarnings] = useState(0);
const [totalEarnings, setTotalEarnings] = useState(0);



  // Fetch data for each tab
  const fetchOrders = async () => {
    try {
      // const response = await axios.get('http://192.168.29.80:5001/fetch-all-orders');
      const response = await axios.get('https://cosmyk.uc.r.appspot.com/fetch-all-orders');
      const allOrders = response.data.all_orders;

      // Ensure case-insensitive comparison for the status field
      setPendingOrders(allOrders.filter(order => order.status.toLowerCase() === 'pending'));
      setDispatchedOrders(allOrders.filter(order => order.status.toLowerCase() === 'dispatched'));
      setCompletedOrders(allOrders.filter(order => order.status.toLowerCase() === 'completed'));
    } catch (error) {
      console.error('Error fetching order data:', error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const calculateEarningsAndOrders = () => {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    let todayOrders = 0;
    let totalOrders = completedOrders.length;
  
    completedOrders.forEach(order => {
      if (order.completed_at) {
        const orderDate = new Date(order.completed_at).toISOString().split('T')[0];
        if (orderDate === today) {
          todayOrders += 1;
        }
      }
    });
  
    const earningsPerOrder = 25; // Earnings per order
    setTodayCompletedOrders(todayOrders);
    setTotalCompletedOrders(totalOrders);
    setTodayEarnings(todayOrders * earningsPerOrder);
    setTotalEarnings(totalOrders * earningsPerOrder);
  };
  
  
  useEffect(() => {
    calculateEarningsAndOrders();
  }, [completedOrders]);
  

  const styles = {
    dashboardWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '-20px',
      minHeight: '100vh',
      overflowY: 'auto',
      padding: '0 10px', // Add some padding for smaller screens
    },
    container: {
      padding: '10px 20px',
      fontFamily: 'Arial, sans-serif',
      maxWidth: '1000px',
      width: '100%',
    },
    heading: {
      margin: '5px 0',
      textAlign: 'center',
    },
    logo: {
      width: '100px',
      height: '100px',
      marginBottom: '5px',
    },
    tabsContainer: {
      display: 'flex',
      flexWrap: 'wrap', // Allow tabs to wrap on smaller screens
      justifyContent: 'space-between',
      marginBottom: '15px',
    },
    tab: (isSelected) => ({
      backgroundColor: isSelected ? '#C5CAE9' : '#E8EAF6',
      padding: '8px 15px',
      borderRadius: '5px',
      cursor: 'pointer',
      border: isSelected ? '2px solid #3f51b5' : 'none',
      textAlign: 'center',
      fontSize: '14px',
      transition: 'background-color 0.3s',
      whiteSpace: 'nowrap',
      margin: '5px', // Reduced margin for better fit on small screens
    }),
    tabContent: {
      marginTop: '20px',
      fontSize: '16px',
      textAlign: 'center',
    },
    cardContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      gap: '20px',
      marginTop: '20px',
    },
    card: {
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '15px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      backgroundColor: '#fff',
    },
    cardInfo: {
      marginTop: '10px',
    },
    cardTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#3f51b5',
    },
    cardText: {
      fontSize: '14px',
      color: '#555',
      margin: '5px 0',
    },
    button: {
      backgroundColor: 'black',
      border: '2px solid white',
      color: 'white',
      padding: '10px 30px',
      fontSize: '1rem',
      cursor: 'pointer',
      borderRadius: '100px',
      transition: 'background-color 0.3s',
      boxSizing: 'border-box',
      marginTop: '15px',
    },
  };

  const handleDispatch = async (orderId) => {
    try {
      // const response = await axios.put(`http://192.168.29.80:5001/update-order-status/${orderId}`);
      const response = await axios.put(`https://cosmyk.uc.r.appspot.com/update-order-status/${orderId}`);
      if (response.status === 200) {
        alert('Order dispatched successfully!');
        fetchOrders(); // Refresh the data after dispatch
      } else {
        alert('Failed to dispatch the order.');
      }
    } catch (error) {
      console.error('Error dispatching order:', error);
      alert('An error occurred while dispatching the order.');
    }
  };

  const handleComplete = async (orderId) => {
    try {
      // const response = await axios.post(`http://192.168.29.80:5001/complete-order/${orderId}`);
      const response = await axios.post(`https://cosmyk.uc.r.appspot.com/complete-order/${orderId}`);
      if (response.status === 200) {
        alert('Order marked as completed!');
        fetchOrders(); // Refresh the data after completing the order
      } else {
        alert('Failed to mark order as completed.');
      }
    } catch (error) {
      console.error('Error completing order:', error);
    }
  };

  return (
    <div style={styles.dashboardWrapper}>
      <div style={styles.container}>
        <img src={logo} alt="Cosmyk Logo" style={styles.logo} />
        <h6 style={styles.heading}>Delivery Boy Dashboard</h6>

        <div style={styles.tabsContainer}>
          <div
            style={styles.tab(selectedTab === 'Pending Orders')}
            onClick={() => setSelectedTab('Pending Orders')}
          >
            Pending Orders
          </div>
          <div
            style={styles.tab(selectedTab === 'Dispatched Orders')}
            onClick={() => setSelectedTab('Dispatched Orders')}
          >
            Dispatched Orders
          </div>
          <div
            style={styles.tab(selectedTab === 'Completed Orders')}
            onClick={() => setSelectedTab('Completed Orders')}
          >
            Completed Orders
          </div>
          <div
  style={styles.tab(selectedTab === 'My Earnings')}
  onClick={() => setSelectedTab('My Earnings')}
>
  My Cosmyk Earnings
</div>

        </div>

        <div style={styles.tabContent}>
          {selectedTab === 'Pending Orders' && (
            <div>
              {pendingOrders.length > 0 ? (
                <div style={styles.cardContainer}>
                  {pendingOrders.map((order) => (
                    <div key={order._id} style={styles.card}>
                      <div style={styles.cardInfo}>
                        <div style={styles.cardTitle}><strong>Order ID:</strong> {order._id}</div>
                        <div style={styles.cardText}><strong>User Name:</strong> {order.user_name}</div>
                        <div style={styles.cardText}><strong>User Mobile:</strong> {order.user_mobile}</div>
                        <div style={styles.cardText}><strong>User Address:</strong> {`${order.user_address?.house_number}, ${order.user_address?.street}, ${order.user_address?.city}, ${order.user_address?.state}, ${order.user_address?.pincode}`}</div>
                        
                        {/* Ordered Products */}
                        <div style={styles.cardText}>
                          <strong>Ordered Products:</strong> 
                          {order.ordered_products.map((product, index) => (
                            <div key={index}>
                              {product.product_name} (x{product.quantity})
                            </div>
                          ))}
                        </div>
                        
                        {/* Total Amount */}
                        <div style={styles.cardText}><strong>Total Amount:</strong> ₹ {order.total_amount}</div>
                        
                        {/* Status */}
                        <div style={styles.cardText}><strong>Status:</strong> {order.status}</div>
                        
                        {/* Created At */}
                        <div style={styles.cardText}><strong>Created At:</strong> {new Date(order.created_at).toLocaleString()}</div>
                      </div>
                      <button
                        style={styles.button}
                        onClick={() => handleDispatch(order._id)} // Pass the order ID to the dispatch handler
                      >
                        Dispatch Order
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No pending orders.</p>
              )}
            </div>
          )}

          {selectedTab === 'Dispatched Orders' && (
            <div>
              {dispatchedOrders.length > 0 ? (
                <div style={styles.cardContainer}>
                  {dispatchedOrders.map((order) => (
                    <div key={order._id} style={styles.card}>
                      <div style={styles.cardInfo}>
                        <div style={styles.cardTitle}><strong>Order ID:</strong> {order._id}</div>
                        <div style={styles.cardText}><strong>User Name:</strong> {order.user_name}</div>
                        <div style={styles.cardText}><strong>User Mobile:</strong> {order.user_mobile}</div>
                        <div style={styles.cardText}><strong>User Address:</strong> {`${order.user_address?.house_number}, ${order.user_address?.street}, ${order.user_address?.city}, ${order.user_address?.state}, ${order.user_address?.pincode}`}</div>
                        
                        {/* Ordered Products */}
                        <div style={styles.cardText}>
                          <strong>Ordered Products:</strong> 
                          {order.ordered_products.map((product, index) => (
                            <div key={index}>
                              {product.product_name} (x{product.quantity})
                            </div>
                          ))}
                        </div>
                        
                        {/* Total Amount */}
                        <div style={styles.cardText}><strong>Total Amount:</strong> ₹ {order.total_amount}</div>
                        
                        {/* Status */}
                        <div style={styles.cardText}><strong>Status:</strong> {order.status}</div>
                        
                        {/* Created At */}
                        <div style={styles.cardText}><strong>Dispatched At:</strong> {new Date(order.dispatched_at).toLocaleString()}</div>
                      </div>
                      <button
                        style={styles.button}
                        onClick={() => handleComplete(order._id)} // Pass the order ID to the complete handler
                      >
                        Complete Order
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No dispatched orders.</p>
              )}
            </div>
          )}

          {selectedTab === 'Completed Orders' && (
            <div>
              {completedOrders.length > 0 ? (
                <div style={styles.cardContainer}>
                  {completedOrders.map((order) => (
                    <div key={order._id} style={styles.card}>
                      <div style={styles.cardInfo}>
                        <div style={styles.cardTitle}><strong>Order ID:</strong> {order._id}</div>
                        <div style={styles.cardText}><strong>User Name:</strong> {order.user_name}</div>
                        <div style={styles.cardText}><strong>User Mobile:</strong> {order.user_mobile}</div>
                        <div style={styles.cardText}><strong>User Address:</strong> {`${order.user_address?.house_number}, ${order.user_address?.street}, ${order.user_address?.city}, ${order.user_address?.state}, ${order.user_address?.pincode}`}</div>
                        
                        {/* Ordered Products */}
                        <div style={styles.cardText}>
                          <strong>Ordered Products:</strong> 
                          {order.ordered_products.map((product, index) => (
                            <div key={index}>
                              {product.product_name} (x{product.quantity})
                            </div>
                          ))}
                        </div>
                        
                        {/* Total Amount */}
                        <div style={styles.cardText}><strong>Total Amount:</strong> ₹ {order.total_amount}</div>
                        
                        {/* Status */}
                        <div style={styles.cardText}><strong>Status:</strong> {order.status}</div>
                        
                        {/* Created At */}
                        <div style={styles.cardText}><strong>Completed At:</strong> {new Date(order.completed_at).toLocaleString()}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>No completed orders.</p>
              )}
            </div>
          )}

{selectedTab === 'My Earnings' && (
  <div>
    <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>My Earnings</h3>
    <div style={styles.card}>
      <p style={styles.cardText}>
        <strong>Total Orders Completed Today:</strong> {todayCompletedOrders}
      </p>
      <p style={styles.cardText}>
        <strong>Total Orders Completed Till Date:</strong> {totalCompletedOrders}
      </p>
      <p style={styles.cardText}>
        <strong>Today's Earnings:</strong> ₹ {todayEarnings}
      </p>
      <p style={styles.cardText}>
        <strong>Total Earnings:</strong> ₹ {totalEarnings}
      </p>
    </div>
  </div>
)}



        </div>
      </div>
    </div>
  );
};

export default DeliveryBoyDashboard;
