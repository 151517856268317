import React, { useState } from 'react';
import logo from '../cowhite.png'; // Adjust the path as necessary

const CosmykDashboard = () => {
  const [selectedTab, setSelectedTab] = useState('Add Store Email');

  const styles = {
    dashboardWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '-20px',
      minHeight: '100vh',
      overflowY: 'auto', // Allows vertical scrolling for the entire dashboard
    },
    container: {
      padding: '10px 20px',
      fontFamily: 'Arial, sans-serif',
      color: '#333',
      maxWidth: '800px', // Set a max width to keep the content centered
      width: '100%', // Ensure the container takes the full width
    },
    heading: {
      margin: '5px 0',
      textAlign: 'center',
    },
    logo: {
      width: '100px',
      height: '100px',
      marginBottom: '5px',
    },
    tabsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: '15px',
    },
    tab: (isSelected) => ({
      backgroundColor: isSelected ? '#C5CAE9' : '#E8EAF6',
      padding: '8px 15px',
      borderRadius: '5px',
      margin: '5px',
      cursor: 'pointer',
      border: isSelected ? '2px solid #3f51b5' : 'none',
      textAlign: 'center',
      flex: '1 0 25%',
      fontSize: '14px',
      transition: 'background-color 0.3s',
    }),
    contentArea: {
      marginTop: '20px',
      fontSize: '16px',
      textAlign: 'center',
    },
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 'Add Store Email':
        return <p>Form or functionality to add a store email will go here.</p>;
      case 'Add Delivery Boy':
        return <p>Form or functionality to add delivery boy details will go here.</p>;
      case 'Add Commission Email':
        return <p>Form or functionality to add commission email will go here.</p>;
      case 'Store Commission':
        return <p>Store commission details and metrics will be displayed here.</p>;
      case 'Restaurant Commission':
        return <p>Restaurant commission details and metrics will be displayed here.</p>;
      case 'Total Cosmyk Sales':
        return <p>Total Cosmyk sales metrics will be displayed here.</p>;
      default:
        return <p>Select a tab to view content.</p>;
    }
  };

  return (
    <div style={styles.dashboardWrapper}>
      <div style={styles.container}>
        <img src={logo} alt="Logo" style={styles.logo} />
        <h6 style={styles.heading}>Cosmyk Dashboard</h6>
        <div style={styles.tabsContainer}>
          <div
            style={styles.tab(selectedTab === 'Add Store Email')}
            onClick={() => setSelectedTab('Add Store Email')}
          >
            Add Email for Store Approval
          </div>
          <div
            style={styles.tab(selectedTab === 'Add Delivery Boy')}
            onClick={() => setSelectedTab('Add Delivery Boy')}
          >
            Add Email for Delivery Boy
          </div>
          <div
            style={styles.tab(selectedTab === 'Add Commission Email')}
            onClick={() => setSelectedTab('Add Commission Email')}
          >
            Add Email for Commission Check
          </div>
          <div
            style={styles.tab(selectedTab === 'Store Commission')}
            onClick={() => setSelectedTab('Store Commission')}
          >
            Store Commission
          </div>
          <div
            style={styles.tab(selectedTab === 'Restaurant Commission')}
            onClick={() => setSelectedTab('Restaurant Commission')}
          >
            Restaurant Commission
          </div>
          <div
            style={styles.tab(selectedTab === 'Total Cosmyk Sales')}
            onClick={() => setSelectedTab('Total Cosmyk Sales')}
          >
            Total Cosmyk Sales
          </div>
        </div>
        <div style={styles.contentArea}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default CosmykDashboard;
