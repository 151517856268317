import React, { useState, useEffect } from 'react';
import logo from '/Users/manikantayedlapally/abcd/src/cowhite.png';

// SetupStore Component
const SetupStore = ({ onStoreSetup }) => {
  const [storeName, setStoreName] = useState('');
  const [storeEmail, setStoreEmail] = useState('');
  const [storeMobile, setStoreMobile] = useState('');
  const [storeImage, setStoreImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requestStatus, setRequestStatus] = useState('');
  const [storeStatus, setStoreStatus] = useState(null); // Track the current store status


  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const email = localStorage.getItem('userEmail');
        if (!email) {
          console.error('No email found in localStorage.');
          setLoading(false);
          return;
        }
  
        // const response = await fetch(`http://192.168.29.80:5001/get-user-details?email=${email}`);
        const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-user-details?email=${email}`);
        const data = await response.json();
  
        if (response.ok) {
          setStoreName(data.name);
          setStoreEmail(email);
          setStoreMobile(data.mobile || '');
  
          // Fetch the store status from the API
          // const storeStatusResponse = await fetch(`http://192.168.29.80:5001/fetch-store-status?email=${email}`);
          const storeStatusResponse = await fetch(`https://cosmyk.uc.r.appspot.com/fetch-store-status?email=${email}`);
          const storeStatusData = await storeStatusResponse.json();
  
          console.log('Fetched store status:', storeStatusData); // Log for debugging
  
          if (storeStatusResponse.ok) {
            setStoreStatus(storeStatusData.status);
            if (storeStatusData.status === 'store approved') {
              onStoreSetup('store approved'); // Trigger parent callback to show Add Product form
            } else {
              setRequestStatus(`Your store setup is currently: ${storeStatusData.status}`);
            }
          } else {
            console.error('Error fetching store status:', storeStatusData.error);
          }
        } else {
          console.error('Error from API:', data.error);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserDetails();
  }, [onStoreSetup]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setStoreImage(file);
    }
  };

  const handleStoreSetup = async () => {
    if (!storeName || !storeEmail || !storeMobile || !storeImage) {
      alert('Please fill out all fields and upload a store image.');
      return;
    }

    const formData = new FormData();
    formData.append('email', storeEmail);
    formData.append('store_image', storeImage);

    try {
      // const response = await fetch('http://192.168.29.80:5001/request-store',
        const response = await fetch('https://cosmyk.uc.r.appspot.com/request-store', 
          {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();

      if (response.ok) {
        setStoreStatus('store requested'); // Update store status to pending
        setRequestStatus('Store setup requested successfully. Please wait for approval.');
      } else {
        setRequestStatus(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error requesting store setup:', error);
      setRequestStatus('An error occurred while requesting store setup.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  // Conditional rendering based on store status
  return (
    <div style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <h6 style={styles.heading}>setup your store to add products</h6>
      {storeStatus === 'store approved' ? (
        <p>Your store is approved. You can now add products.</p>
      ) : storeStatus === 'store requested' ? (
        <p>Your store setup is pending approval. Please wait.</p>
      ) : (
        <>
          <div style={styles.card}>
            <input
              type="text"
              style={styles.input}
              value={storeName}
              readOnly
              placeholder="Store Name"
            />
          </div>
          <div style={styles.card}>
            <input
              type="email"
              style={styles.input}
              value={storeEmail}
              readOnly
              placeholder="Email"
            />
          </div>
          <div style={styles.card}>
            <input
              type="text"
              style={styles.input}
              value={storeMobile}
              readOnly
              placeholder="Mobile Number"
            />
          </div>
          <button style={styles.button} onClick={() => document.getElementById('storeImageUpload').click()}>
            Upload Store Image / Logo
          </button>
          <input
            id="storeImageUpload"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />

          {storeImage && (
            <div style={styles.imagePreviewContainer}>
              <img src={URL.createObjectURL(storeImage)} alt="Store Logo" style={styles.imagePreview} />
            </div>
          )}

          <button style={styles.button} onClick={handleStoreSetup}>
            Request Store Setup
          </button>

          {/* Display request status message */}
          {requestStatus && <p style={styles.confirmation}>{requestStatus}</p>}
        </>
      )}
    </div>
  );
};

const AddProduct = () => {
  const [isStoreSetup, setIsStoreSetup] = useState(false);
  const [storeStatus, setStoreStatus] = useState(null);

  const handleStoreSetup = (status) => {
    setIsStoreSetup(true);
    setStoreStatus(status);
  };

  return (
    <div>
      {storeStatus === 'store approved' ? (
        <ProductForm />
      ) : (
        <SetupStore onStoreSetup={handleStoreSetup} />
      )}
      {/* Message for pending store requests */}
      {storeStatus === 'store requested' && <p>Your store is pending approval. Please wait.</p>}
    </div>
  );
};

// ProductForm Component
const ProductForm = () => {
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productStock, setProductStock] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productImages, setProductImages] = useState([]);
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loadingVisible, setLoadingVisible] = useState(false);

  useEffect(() => {
      const fetchUserDetails = async () => {
          try {
              const email = localStorage.getItem('userEmail');
              if (!email) {
                  console.error('No email found in localStorage.');
                  setLoading(false);
                  return;
              }

              // const response = await fetch(`http://192.168.29.80:5001/get-user-details?email=${email}`);
              const response = await fetch(`https://cosmyk.uc.r.appspot.com/get-user-details?email=${email}`);
              const data = await response.json();

              if (response.ok) {
                  setUserName(data.name);
              } else {
                  console.error('Error from API:', data.error);
              }
          } catch (error) {
              console.error('Error fetching user details:', error);
          } finally {
              setLoading(false);
          }
      };

      fetchUserDetails();
  }, []);

  if (loading) {
      return <p>Loading...</p>;
  }

  const handleAddProduct = async () => {
      if (!productName || !productPrice || !productStock || !productDescription || productImages.length === 0) {
          alert('Please fill out all fields and upload at least one product image.');
          return;
      }

      const email = localStorage.getItem('userEmail');
      if (!email) {
          alert('User email is not available.');
          return;
      }

      const formData = new FormData();
      formData.append('product_name', productName);
      formData.append('product_price', productPrice);
      formData.append('stock_quantity', productStock);
      formData.append('product_description', productDescription);
      formData.append('email', email);

      // Append multiple images to the form data
      productImages.forEach((image) => {
          formData.append('files', image.file);
      });

      try {
        setLoadingVisible(true); 
          // const response = await fetch('http://192.168.29.80:5001/add-product',
            const response = await fetch('https://cosmyk.uc.r.appspot.com/add-product', 
               {
              method: 'POST',
              body: formData,
          });

          const data = await response.json();

          if (response.ok) {
              // alert('Product added successfully!');
              setShowConfirmation(true);
              resetForm();
          } else {
              alert(`Error: ${data.error}`);
          }
      } catch (error) {
          console.error('Error adding product:', error);
          alert('An error occurred while adding the product.');
      }
      finally {
        setLoadingVisible(false); // Hide loading message after the fetch completes
      }
  };

  const resetForm = () => {
      setProductName('');
      setProductPrice('');
      setProductStock('');
      setProductDescription('');
      setProductImages([]);
  };

  const handleImageUpload = (event) => {
      const files = Array.from(event.target.files);

      if (files.length + productImages.length > 5) {
          alert('You can only upload up to 5 images. Please select fewer images.');
          return;
      }

      const newFiles = files.map((file) => ({
          file,
          preview: URL.createObjectURL(file),
      }));

      setProductImages((prevImages) => [...prevImages, ...newFiles]);
  };

  const handleRemoveImage = (index) => {
      setProductImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
      <div style={styles.container}>
          <img src={logo} alt="Logo" style={styles.logo} />
          <h6 style={styles.heading}>Add Product</h6>
          <div style={styles.card}>
              <input
                  type="text"
                  style={styles.input}
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  placeholder="Product Name"
              />
          </div>

      {loadingVisible && (
  <div style={{
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '24px',
    zIndex: 9999,
  }}>
    Adding new product to your store...
  </div>
)}
          <div style={styles.card}>
              <input
                  type="number"
                  style={styles.input}
                  value={productPrice}
                  onChange={(e) => setProductPrice(e.target.value)}
                  placeholder="Product Price"
              />
          </div>
          <div style={styles.card}>
              <input
                  type="number"
                  style={styles.input}
                  value={productStock}
                  onChange={(e) => setProductStock(e.target.value)}
                  placeholder="Stock Quantity"
              />
          </div>
          <div style={styles.card}>
              <textarea
                  style={styles.input}
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                  placeholder="Product Description"
              />
          </div>
          <button style={styles.button} onClick={() => document.getElementById('productImageUpload').click()}>
              Upload Product Images
          </button>
          <input
              id="productImageUpload"
              type="file"
              accept="image/*"
              multiple
              style={{ display: 'none' }}
              onChange={handleImageUpload}
          />

          <div style={styles.imagePreviewContainer}>
              {productImages.map((image, index) => (
                  <div key={index} style={styles.imagePreviewWrapper}>
                      <img src={image.preview} alt={`Product Preview ${index + 1}`} style={styles.imagePreview} />
                      <button style={styles.removeButton} onClick={() => handleRemoveImage(index)}>
                          X
                      </button>
                  </div>
              ))}
          </div>

          <button style={styles.button} onClick={handleAddProduct}>
              Add Product
          </button>
          {showConfirmation && <p style={styles.confirmation}>Product added successfully - Visit profile or add another product</p>}
      </div>
  );
};

// Styles
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '20px',
        padding: '20px',
    },
    heading: {
        marginBottom: '20px',
        textAlign: 'center',
    },
    card: {
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '10px',
        marginBottom: '10px',
        width: '100%',
        maxWidth: '500px',
    },
    input: {
        width: '100%',
        maxWidth: '400px',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '16px',
        marginBottom: '10px',
    },
    button: {
        backgroundColor: 'black',
        border: '2px solid white',
        color: 'white',
        padding: '10px 30px',
        fontSize: '1rem',
        cursor: 'pointer',
        borderRadius: '100px',
        transition: 'background-color 0.3s',
        boxSizing: 'border-box',
        marginBottom: '10px',
    },
    imagePreviewContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10px',
    },
    imagePreviewWrapper: {
        position: 'relative',
        marginBottom: '10px',
    },
    imagePreview: {
        width: '100px',
        height: '100px',
        objectFit: 'cover',
        borderRadius: '4px',
        marginRight: '10px',
    },
    removeButton: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        backgroundColor: 'red',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        cursor: 'pointer',
        padding: '2px 5px',
    },
    confirmation: {
        marginTop: '20px',
        color: 'black',
        textAlign: 'center',
    },
    logo: {
        width: '200px',
        height: 'auto',
        marginTop: '-40px',
        marginBottom: '-20px',
    },
};

export default AddProduct;
