import React, { useState } from 'react';
import logo from '/Users/manikantayedlapally/abcd/src/cowhite.png';

const AddKeywords = () => {
  const [keywords, setKeywords] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddKeywords = async () => {
    const email = localStorage.getItem('userEmail');
    if (!email) {
      alert('User email is not available.');
      return;
    }
  
    // Split, trim, convert to lowercase, and filter out empty strings
    const keywordsArray = keywords
      .split(',')
      .map((keyword) => keyword.trim().toLowerCase()) // Convert to lowercase
      .filter((keyword) => keyword.length > 0); // Remove empty strings
  
    if (keywordsArray.length === 0) {
      alert('Please enter at least one valid keyword.');
      return;
    }
  
    setLoading(true);
    try {
    //   const response = await fetch('http://192.168.29.80:5001/add-keywords', 
        const response = await fetch('https://cosmyk.uc.r.appspot.com/add-keywords', 
        {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          keywords: keywordsArray,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setStatusMessage('Keywords added successfully!');
      } else {
        setStatusMessage(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error adding keywords:', error);
      setStatusMessage('An error occurred while adding keywords.');
    } finally {
      setLoading(false);
      setKeywords(''); // Clear the input field after submission
    }
  };
  
  

  return (
    <div style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <h6 style={styles.heading}>Add Keywords - to search your store in main page - Example food, clothes, watch, etc</h6>
      <div style={styles.card}>
        <input
          type="text"
          style={styles.input}
          value={keywords}
          onChange={(e) => setKeywords(e.target.value)}
          placeholder="Enter keywords separated by commas"
        />
      </div>
      <button style={styles.button} onClick={handleAddKeywords} disabled={loading}>
        {loading ? 'Submitting...' : 'Submit'}
      </button>
      {statusMessage && <p style={styles.statusMessage}>{statusMessage}</p>}
    </div>
  );
};

// Styles
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px',
    padding: '20px',
  },
  logo: {
    width: '200px',
    height: 'auto',
    marginBottom: '20px',
  },
  heading: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '10px',
    marginBottom: '10px',
    width: '100%',
    maxWidth: '500px',
  },
  input: {
    width: '100%',
    maxWidth: '400px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    marginBottom: '10px',
  },
  button: {
    backgroundColor: 'black',
    border: '2px solid white',
    color: 'white',
    padding: '10px 30px',
    fontSize: '1rem',
    cursor: 'pointer',
    borderRadius: '100px',
    transition: 'background-color 0.3s',
    boxSizing: 'border-box',
    marginBottom: '10px',
  },
  statusMessage: {
    marginTop: '20px',
    color: 'black',
    textAlign: 'center',
  },
};

export default AddKeywords;
