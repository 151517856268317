import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const SummaryContainer = styled.div`
  padding: 30px;
  font-family: 'Poppins', sans-serif;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 20px;
`;

const AppointmentDetails = styled.div`
  margin: 15px 0;
  background: #fff;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  padding: 15px;
`;

const AppointmentItem = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #333;
`;

const InputContainer = styled.div`
  margin: 15px 0;
`;

const Input = styled.input`
  margin-left: 10px;
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const WarningMessage = styled.div`
  color: red;
  font-weight: 600;
  margin: 10px 0;
`;

const MemberContainer = styled.div`
  margin: 15px 0;
`;

const MemberInput = styled(Input)`
  margin: 5px 5px 5px 0;
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 12px;
  color: white;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
`;

const ConfirmButton = styled(Button)`
  background-color: black;
`;

const BackButton = styled(Button)`
  background-color: black;
`;

const AppointmentSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { appointment, email } = location.state; // Retrieve store and user emails
  const userEmail = localStorage.getItem('userEmail');

  const [slotsInput, setSlotsInput] = useState('');
  const [members, setMembers] = useState([]);
  const [warningMessage, setWarningMessage] = useState('');

  const handleSlotsChange = (event) => {
    const numberOfSlots = parseInt(event.target.value, 10);

    if (numberOfSlots > appointment.slots) {
      setWarningMessage('Warning: Number of slots exceeded available slots.');
    } else {
      setWarningMessage('');
    }

    setSlotsInput(numberOfSlots || '');

    const newMembers = Array.from({ length: numberOfSlots }, () => ({
      name: '',
      phone: '',
    }));
    setMembers(newMembers);
  };

  const handleMemberChange = (index, field, value) => {
    const updatedMembers = [...members];
    updatedMembers[index][field] = value;
    setMembers(updatedMembers);
  };

  const isBookingValid = () => {
    if (!slotsInput || slotsInput <= 0 || slotsInput > appointment.slots) return false;
    return members.every((member) => member.name && member.phone);
  };

  const handleBookAppointment = async () => {
    const appointmentData = {
      store_email: email,  // Use the store email passed from FetchAppointments
      user_email: userEmail,    // Use the user email passed from FetchAppointments
      appointment_title: appointment.title,
      appointment_date: appointment.date,
      appointment_time: appointment.time,
      slots_booked: slotsInput,
      total_amount: appointment.price * slotsInput,
      members: members,
    };

    try {
      // const response = await fetch('http://192.168.29.80:5001/book-appointment',
        const response = await fetch('https://cosmyk.uc.r.appspot.com/book-appointment', 
          {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(appointmentData),
      });

      const data = await response.json();
      console.log('API Response:', data);

      if (response.ok) {
        alert('Appointment booked successfully!');
        navigate('/');
      } else {
        alert(`Error: ${data.error || data.message}`);
      }
    } catch (error) {
      console.error('Error booking appointment:', error);
      alert('An error occurred while booking the appointment.');
    }
  };

  return (
    <SummaryContainer>
      <Header>
        <h6>Appointment Summary</h6>
      </Header>
      <AppointmentDetails>
        <AppointmentItem><strong>Title:</strong> {appointment.title}</AppointmentItem>
        <AppointmentItem><strong>Date:</strong> {appointment.date}</AppointmentItem>
        <AppointmentItem><strong>Time:</strong> {appointment.time}</AppointmentItem>
        <AppointmentItem><strong>Location:</strong> {appointment.location}</AppointmentItem>
        <AppointmentItem><strong>Details:</strong> {appointment.description}</AppointmentItem>
        {/* <AppointmentItem><strong>Slots Available:</strong> {appointment.slots}</AppointmentItem> */}
        {/* <AppointmentItem><strong>Store Email:</strong> {email}</AppointmentItem> Display store email */}
        {/* <AppointmentItem><strong>User Email:</strong> {userEmail}</AppointmentItem> Display user email */}
      </AppointmentDetails>

      <InputContainer>
        <label><strong>Enter number of slots required:</strong></label>
        <Input
          type="number"
          value={slotsInput}
          onChange={handleSlotsChange}
          max={appointment.slots}
        />
      </InputContainer>

      {warningMessage && <WarningMessage>{warningMessage}</WarningMessage>}

      {slotsInput > 0 && slotsInput <= appointment.slots && members.map((member, index) => (
        <MemberContainer key={index}>
          <label><strong>Member {index + 1} Details:</strong></label>
          <div>
            <MemberInput
              type="text"
              placeholder="Name"
              value={member.name}
              onChange={(e) => handleMemberChange(index, 'name', e.target.value)}
            />
            <MemberInput
              type="text"
              placeholder="Phone Number"
              value={member.phone}
              onChange={(e) => handleMemberChange(index, 'phone', e.target.value)}
            />
          </div>
        </MemberContainer>
      ))}

      <ConfirmButton
        onClick={handleBookAppointment}
        disabled={!isBookingValid()}
      >
        Confirm & Book Slots
      </ConfirmButton>

      <BackButton onClick={() => navigate(-1)}>Go Back</BackButton>
    </SummaryContainer>
  );
};

export default AppointmentSummary;